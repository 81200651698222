import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Container, Typography, Box } from '@mui/material';
import Header from '../components/header/Header';
import config from '../config';
import './Myaccount.css';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const MyAccount = () => {
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');
    const [isEditing, setIsEditing] = useState(false); 
    const {t} = useTranslation();
    const [userData, setUserData] = useState({
      firstName: '',
      surname: '',
      email: '',
      telephone: '',
      mobile: '',
      company: '',
      streetNo: '',
      postcode: '',
      town: '',
      country: '',
      poBox: '',
      carDealership: '',
      motorcycleDealership: '',
      commercialVehicleDealership: '',
      addition: '',
      preferredLanguage: '',
    });
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${config.API_URL}/myaccount`, {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          });
          const result = await response.json();
          const details = result.details;
  
          setUserData({
            firstName: details.firstName,
            surname: details.surname,
            email: details.email,
            telephone: details.telephone,
            mobile: details.mobile,
            company: details.company,
            streetNo: details.streetNo,
            postcode: details.postcode,
            town: details.town,
            country: details.country,
            poBox: details.poBox,
            carDealership: details.carDealership,
            motorcycleDealership: details.motorcycleDealership,
            commercialVehicleDealership: details.commercialVehicleDealership,
            addition: details.addition,
            preferredLanguage: details.preferredLanguage,
          });
  
          setLoading(false);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(false);
        }
      };
  
      fetchUserData();
    }, []);
  

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUserData({
        ...userData,
        [name]: value,
      });
    };
  

    const toggleEditMode = (e) => {
      e.preventDefault(); 
      setIsEditing(true); 
    };
  

    const handleSubmit = async (e) => {
      e.preventDefault(); 
  
      try {
        const response = await fetch(`${config.API_URL}/savemyaccountdata`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ details: userData })
        });
  
        const result = await response.json();
        
        if (response.ok) {
          alert(result.message);
          setIsEditing(false);
        } else {
          alert(result.error);
        }
      } catch (error) {
        console.error("Error saving account data:", error);
      }
    };

  return (
    <>
    <Header />
    <Container maxWidth="md" className='myAccount'>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('myAccount')}
      </Typography>
      
      <form onSubmit={handleSubmit} className='myaccountform'>
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagefirstname')}
              name="firstName"
              value={userData.firstName}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Surname */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagesurname')}
              name="surname"
              value={userData.surname}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpageemail')}
              name="email"
              type="email"
              value={userData.email}
              fullWidth
              disabled={true}
            />
          </Grid>

          {/* Telephone */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagetelephone')}
              name="telephone"
              value={userData.telephone}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Mobile */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile"
              name="mobile"
              value={userData.mobile}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Company */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagecompany')}
              name="company"
              value={userData.company}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Street Number */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagestreetno')}
              name="streetNo"
              value={userData.streetNo}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Postcode */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagepostcode')}
              name="postcode"
              value={userData.postcode}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Town */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagetown')}
              name="town"
              value={userData.town}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Country */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpagecountry')}
              name="country"
              value={userData.country}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* P.O. Box */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('pobox')}
              name="poBox"
              value={userData.poBox}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>

          {/* Additional fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('registerpageaddition')}
              name="addition"
              value={userData.addition}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>
        </Grid>

        <p style={{ marginTop: '2vh', color: 'red' }}>
          <Trans i18nKey="address_change_notice">
            <a href="mailto:support@autoauction24.ch" style={{ color: 'red' }}>
              support@autoauction24.ch
            </a>
          </Trans>
        </p>
        {/* <Box mt={3}>
            {isEditing ? (
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={toggleEditMode}>
                Edit
              </Button>
            )}
          </Box> */}
      </form>
    </Container>
    </>
  );
};

export default MyAccount;
