import React, { useState, useEffect } from 'react';
import Header from "../components/header/Header";
import config from '../config';
import './Admin.css'; // Add CSS file for styling

export default function Admin() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetch(`${config.API_URL}/getpendingusers`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => response.json())
        .then(data => {
            // Parse the details field and sort users with `active: 0` on top
            const parsedData = data
                .map(user => ({
                    ...user,
                    details: JSON.parse(user.details)
                }))
                .sort((a, b) => a.active - b.active);
            setUsers(parsedData);
        })
        .catch(error => console.error('Error fetching users:', error));
    }, []);

    const handleUserStatusChange = (id, status) => {
        const endpoint = status === 0 ? `/activateuser/${id}` : `/deactivateuser/${id}`;
        fetch(`${config.API_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Reload the user list after successful status change
                setUsers(prevUsers => 
                    prevUsers.map(user => 
                        user.id === id ? { ...user, active: status === 0 ? 1 : 0 } : user
                    )
                );
            }
        window.location.reload();

        })
        .catch(error => console.error(`Error ${status === 0 ? 'activating' : 'deactivating'} user:`, error));
    };

    const renderDetails = (details) => {
        return (
            <table className="details-table">
                <tbody>
                    {Object.entries(details).map(([key, value]) => (
                        <tr key={key}>
                            <td><strong>{key}:</strong></td>
                            <td>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <>
            <Header />
            <div className='adminusertable'>
                <h1>Users</h1>
                <table className="user-table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.username}>
                                <td>{user.username}</td>
                                <td>{renderDetails(user.details)}</td>
                                <td>
                                    {user.active === 0 ? (
                                        <button 
                                            style={{
                                                background: '#1D1E42', 
                                                fontFamily: 'Poppins', 
                                                fontWeight: 'bold', 
                                                color: 'white', 
                                                borderRadius: 30
                                            }} 
                                            onClick={() => handleUserStatusChange(user.id, 0)}
                                        >
                                            Activate
                                        </button>
                                    ) : (
                                        <button 
                                            style={{
                                                background: '#E84545', 
                                                fontFamily: 'Poppins', 
                                                fontWeight: 'bold', 
                                                color: 'white', 
                                                borderRadius: 30
                                            }} 
                                            onClick={() => handleUserStatusChange(user.id, 1)}
                                        >
                                            Deactivate
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
