import React, { useState, useEffect } from 'react';
import { unstable_useNumberInput as useNumberInput } from '@mui/base/unstable_useNumberInput';
import { styled } from '@mui/system';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Button, Snackbar, Alert } from '@mui/material';
import { updateCurrentBidPrice } from '../../services/fetchData';
import './Counter.css';

const CompactNumberInput = React.forwardRef(function CompactNumberInput(props, ref) {
  const {
    getRootProps,
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput({ ...props, step: 100 });

  const inputProps = getInputProps();
  inputProps.ref = useForkRef(inputProps.ref, ref);

  return (
    <StyledInputRoot {...getRootProps()}>
      <StyledStepperButton className="increment" {...getIncrementButtonProps()}>
        <ArrowDropUpRoundedIcon />
      </StyledStepperButton>
      <StyledStepperButton className="decrement" {...getDecrementButtonProps()}>
        <ArrowDropDownRoundedIcon />
      </StyledStepperButton>
      <HiddenInput {...inputProps} />
    </StyledInputRoot>
  );
});

export default function Counter({ car, currentBidPrice, minimumprice, onBidUpdate }) {
  const [value, setValue] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const initialBidPrice = currentBidPrice ?? car.currentbidprice ?? minimumprice;
    setValue(initialBidPrice);
  }, [currentBidPrice, car.currentbidprice, minimumprice]);

  const handlePlaceBid = async () => {
    try {
      const result = await updateCurrentBidPrice(car.id, value);
      console.log('Bid placed successfully:', result);
      setSuccessMessage(true);
      if (onBidUpdate) {
        onBidUpdate(); // Notify parent component to handle bid update
      }
    } catch (error) {
      console.error('Failed to place bid:', error);
      if (error.response && error.response.data.error === "You cannot bid for your car") {
        setErrorMessage("You cannot bid for your car");
      } else {
        setErrorMessage("You cannot bid.");
      }
    }
  };

  const handleChange = (event, val) => {
    const newValue = Math.max(val, car.currentbidprice);
    setValue(newValue);
  };

  const nextValue = value + 100;
  const prevValue = value - 100;

  return (
    <>
      <div className='compactnumberone'>
        <Layout>
          <CompactNumberInput
            aria-label="Compact number input"
            value={value}
            onChange={handleChange}
            step={100}
          />
          <BidValues>
            <Value>{nextValue}</Value>
            <CurrentValue>{value}</CurrentValue>
            <Value>{prevValue}</Value>
          </BidValues>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePlaceBid}
            disabled={value <= car.currentbidprice}
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              borderRadius: 19,
              paddingLeft: 20,
              paddingRight: 20,
              backgroundColor: value <= car.currentbidprice ? 'grey' : '#20b605', // Change color based on disabled state
              color: value <= car.currentbidprice ? 'white' : 'white', // Optional: change text color for contrast when disabled
            }}
          >
            Place Bid
          </Button>
          <Snackbar
            open={successMessage || errorMessage !== null}
            autoHideDuration={3000}
            onClose={() => {
              setSuccessMessage(false);
              setErrorMessage(null);
            }}
          >
            {successMessage ? (
              <Alert onClose={() => setSuccessMessage(false)} severity="success">
                Your bid was placed successfully!
              </Alert>
            ) : (
              <Alert onClose={() => setErrorMessage(null)} severity="warning">
                {errorMessage}
              </Alert>
            )}
          </Snackbar>
        </Layout>
      </div>
    </>
  );
}

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const StyledInputRoot = styled('div')(
  ({ theme }) => `
    display: grid;
    grid-template-columns: 2rem;
    grid-template-rows: 2rem 2rem;
    grid-template-areas:
      "increment"
      "decrement";
    row-gap: 1px;
    overflow: auto;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    box-shadow: 0px 2px 4px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
  `,
);

const HiddenInput = styled('input')`
  visibility: hidden;
  position: absolute;
`;

const StyledStepperButton = styled('button')(
  ({ theme }) => `
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  box-sizing: border-box;
  border: 0;
  padding: 0;
  color: inherit;
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  &.increment {
    grid-area: increment;
  }

  &.decrement {
    grid-area: decrement;
  }
`,
);

const Layout = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
`;

const BidValues = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Value = styled('div')`
  font-size: 1.75rem;
  color: grey;
`;

const CurrentValue = styled('div')`
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5rem 0;
`;
