import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { fetchMyCars } from '../../services/fetchData';
import './Mycartable.css';
import { useTranslation } from 'react-i18next';
import config from '../../config';

export default function MyCarsTable() {
  const [rows, setRows] = useState([]);
  const [boughtRows, setBoughtRows] = useState([]);
  const [activeTab, setActiveTab] = useState('notSold');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // State to track screen size
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    const getCars = async () => {
      try {
        const { owned_cars, bought_cars } = await fetchMyCars();
        setRows(owned_cars);
        setBoughtRows(bought_cars);
      } catch (error) {
        console.error('Failed to fetch cars:', error);
      }
    };

    getCars();

    // Event listener for window resizing
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Update isMobile based on window size
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRowClick = (id) => {
    navigate(`/mycar/${id}`);
  };

  const handleEditClick = (e, id) => {
    e.stopPropagation(); // Prevents the row click from firing
    navigate(`/editcar/${id}`);
  };  

  const handleRegisterClick = () => {
    navigate('/registercar');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredRows = activeTab === 'bought' ? boughtRows : rows.filter(row => 
    activeTab === 'notSold' ? row.status === 0 : row.status === 1
  );

  // Render cards for mobile view
  const renderCards = () => (
    <div className="card-container">
      {filteredRows.map((row) => (
        <div key={row.id} className="car-card" onClick={() => handleRowClick(row.id)}>
          <div className="card-header">
            <img src={row.first_image ? `${config.API_URL}${row.first_image}` : ''} alt={row.specifications?.car_name || 'Unknown Car'} />
          </div>
          <div className="card-body">
            <h3>{JSON.parse(row.specifications).car_name}</h3>
            <p>Minimum Price: {row.minimumprice !== null ? row.minimumprice : 'N/A'}</p>
            <p>ID: {row.id}</p>
          </div>
        </div>
      ))}
    </div>
  );

  // Render table for desktop view
  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#f3f3f3' }}>
          <TableCell style={{ background: 'yellow', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold' }}>{t('imageInTable')}</TableCell>
            <TableCell style={{ background: 'yellow', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold' }}>{t('vehicleInTable')}</TableCell>
            <TableCell style={{ background: 'yellow', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold' }} align="right">{t('minimumPriceInTable')}</TableCell>
            <TableCell style={{ background: 'yellow', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold' }} align="right">ID</TableCell>
            {/* Conditionally add the Edit column header */}
            {activeTab === 'notSold' && (
              <TableCell style={{ borderTopRightRadius: 30, background: 'yellow', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold' }} align="right">{t('editbuttontable')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((row) => (
            <>
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => handleRowClick(row.id)}
              style={{ cursor: 'pointer' }}
            >
                          <TableCell>
              <div className="image-container">
                {row.first_image ? (
                  <>
                    <img src={row.first_image ? `${config.API_URL}${row.first_image}` : ''} alt="Car Image" className="thumbnail" />
                    <div className="overlay">
                      <img src={row.first_image ? `${config.API_URL}${row.first_image}` : ''} alt="Car Image" className="enlarged" />
                    </div>
                  </>
                ) : (
                  <span>{t('noImageAvailable')}</span>
                )}
              </div>
            </TableCell>
              <TableCell component="th" scope="row">
                {JSON.parse(row.specifications).car_name}
              </TableCell>
              <TableCell align="right">{row.minimumprice !== null ? row.minimumprice : 'N/A'}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              {/* Conditionally render the Edit button */}
              {activeTab === 'notSold' && (
                <TableCell align="right">
                  <Button variant="outlined" onClick={(e) => handleEditClick(e, row.id)}>
                    {t('editbuttontable')}
                  </Button>
                </TableCell>
              )}
            </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <div className="mycartable">
        <div className="notsold-vehicles">
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="tabs">
            <Tab value="notSold" label={<Typography variant="body1" component="p">{t('notSoldVehicles')}</Typography>}/>
            <Tab value="sold" label={<Typography variant="body1" component="p">{t('soldVehicles')}</Typography>} />
            <Tab value="bought" label={<Typography variant="body1" component="p">{t('boughtVehicles')}</Typography>} />
          </Tabs>
          <div className="registerbutton">
            <Button variant="contained" onClick={handleRegisterClick}>
              {t('addNewCarButton')}
            </Button>
          </div>
        </div>
      </div>
      {isMobile ? renderCards() : renderTable()}
    </div>
  );
}
