import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import './Register.css';
import Spinner from '../components/spinner';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Typography,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Radio,
    Checkbox,
    Button,
    Grid,
    Paper,
} from '@mui/material';
import Header from '../components/header/Header';
import { Trans } from 'react-i18next';

const Register = () => {
    const [registrationType, setRegistrationType] = useState('');
    const {t} = useTranslation();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        company: '',
        addition: '',
        streetNo: '',
        poBox: '',
        postcode: '',
        town: '',
        country: '',
        firstName: '',
        surname: '',
        email: '',
        telephone: '',
        contactPersonFirstName: '',
        contactPersonSurname: '',
        termsAccepted: false,
        alliansAccepted: false,
    });
    const [passwordError, setPasswordError] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (name === 'password') {
            const isValid = passwordRegex.test(value);
            setPasswordError(!isValid);
        }
    };

    const handleRegister = async () => {
        setLoading(true);
        const requiredFields =
            registrationType === 'individual'
                ? ['firstName', 'surname', 'streetNo', 'postcode', 'town', 'email', 'telephone', 'username', 'password', 'preferredLanguage', 'alliansAccepted']
                : [
                      'company',
                      'firstName',
                      'surname',
                      'streetNo',
                      'postcode',
                      'town',
                      'email',
                      'telephone',
                      'username',
                      'password',
                      'preferredLanguage',
                      'alliansAccepted'
                  ];

        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0) {
            alert(`Please fill out the following required fields: ${missingFields.join(', ')}`);
            setLoading(false);
            return;
        }

        if (!formData.termsAccepted) {
            alert('You must accept the terms and conditions.');
            setLoading(false);
            return;
        }

        try {
            await axios.post(`${config.API_URL}/register`, formData);
            alert('Registration successful. Please wait for approval.');
            navigate('/');
        } catch (error) {
            console.error('Registration failed:', error);
            alert('Registration failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <Spinner loading={loading} />
            <Container component="main" maxWidth="md" className="Registrationform">
                <div className='registrationtype' style={{width: '80%', margin: 'auto'}}>
                    <Typography variant="h5" gutterBottom style={{marginBottom: 20, fontWeight: 'bold', fontFamily: 'Poppins'}}>
                        {t('selectregistrationtype')}
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            value={registrationType}
                            onChange={(e) => setRegistrationType(e.target.value)}
                        >
                            <FormControlLabel style={{fontFamily: 'Poppins'}} value="individual" control={<Radio />} label={t('registerasindividual')} />
                            <FormControlLabel style={{fontFamily: 'Poppins'}} value="business" control={<Radio />} label={t('registerasbusiness')} />
                        </RadioGroup>
                    </FormControl>
                </div>
                {registrationType && (
                <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
                    <Grid container spacing={2}>
                        {registrationType === 'individual' && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagefirstname')}
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagesurname')}
                                        name="surname"
                                        value={formData.surname}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagestreetno')}
                                        name="streetNo"
                                        value={formData.streetNo}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('pobox')}
                                        name="poBox"
                                        value={formData.poBox}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagepostcode')}
                                        name="postcode"
                                        value={formData.postcode}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagetown')}
                                        name="town"
                                        value={formData.town}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpageemail')}
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagetelephone')}
                                        name="telephone"
                                        value={formData.telephone}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpageusername')}
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagepassword')}
                                        name="password"
                                        type="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        error={passwordError}
                                        helperText={
                                            passwordError
                                                ? t('passwordmustinclude')
                                                : 'Password looks good!'
                                        }
                                        required
                                    />
                                    
                                </Grid>
                                <Grid item xs={12}>
                                            <FormControl fullWidth variant="outlined" required sx={{ mb: 2 }}>
                                                <InputLabel>{t('preferredlanguage')}</InputLabel>
                                                <Select
                                                    name="preferredLanguage"
                                                    value={formData.preferredLanguage}
                                                    onChange={handleChange}
                                                    label={t('registerpagepreferredlanguage')}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxWidth: '20px',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="en">EN</MenuItem>
                                                    <MenuItem value="de">DE</MenuItem>
                                                    <MenuItem value="it">IT</MenuItem>
                                                    <MenuItem value="fr">FR</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <div className='termscond' style={{marginLeft: 20}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="termsAccepted"
                                                        checked={formData.termsAccepted}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={t('accepttermscondition')}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="alliansAccepted"
                                                        checked={formData.alliansAccepted}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={t('alliansAcceptedIndividual')}
                                            />

                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={handleRegister}
                                                style={{background: 'yellow', color:'black', borderRadius: '30px', width: '30%', fontFamily: 'Poppins'}}
                                            >
                                                {t('registerpagesubmitbutton')}
                                            </Button>
                                        </div>
                            </>
                        )}
                        {registrationType === 'business' && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagecompany')}
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagefirstname')}
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagesurname')}
                                        name="surname"
                                        value={formData.surname}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagestreetno')}
                                        name="streetNo"
                                        value={formData.streetNo}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('pobox')}
                                        name="poBox"
                                        value={formData.poBox}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagepostcode')}
                                        name="postcode"
                                        value={formData.postcode}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagetown')}
                                        name="town"
                                        value={formData.town}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpageemail')}
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagetelephone')}
                                        name="telephone"
                                        value={formData.telephone}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('contactpersonfirstname')}
                                        name="contactPersonFirstName"
                                        value={formData.contactPersonFirstName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('contactpersonsurname')}
                                        name="contactPersonSurname"
                                        value={formData.contactPersonSurname}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpageusername')}
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={t('registerpagepassword')}
                                        name="password"
                                        type="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        error={passwordError}
                                        helperText={
                                            passwordError
                                                ? t('passwordmustinclude')
                                                : 'Password looks good!'
                                        }
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                            <FormControl fullWidth variant="outlined" required sx={{ mb: 2 }}>
                                                <InputLabel>{t('preferredlanguage')}</InputLabel>
                                                <Select
                                                    name="preferredLanguage"
                                                    value={formData.preferredLanguage}
                                                    onChange={handleChange}
                                                    label={t('registerpagepreferredlanguage')}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxWidth: '20px',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="en">EN</MenuItem>
                                                    <MenuItem value="de">DE</MenuItem>
                                                    <MenuItem value="it">IT</MenuItem>
                                                    <MenuItem value="fr">FR</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <div className='termscond' style={{marginLeft: 20}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="termsAccepted"
                                                        checked={formData.termsAccepted}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={t('accepttermscondition')}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="alliansAccepted"
                                                        checked={formData.alliansAccepted}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={t('alliansAccepted')}
                                            />

                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={handleRegister}
                                                style={{background: 'yellow', color:'black', borderRadius: '30px', width: '30%', fontFamily: 'Poppins'}}
                                            >
                                                {t('registerpagesubmitbutton')}
                                            </Button>
                                        </div>
                            </>
                        )}
                    </Grid>
                </Paper>
                )}

            </Container>
        </>
    );
};

export default Register;
